<template>
  <router-view />
</template>

<script>
import { CREATING_RESUME_NAMES, ROUT_PATH } from "@/helpers/rout-constants";
import ResumeHash from "@/helpers/resume-hash";
import AppToast from "@/helpers/toast-methods";
import CompletedTemplate from "@/helpers/completed-template-hash";

export default {
  name: "CreationResume",

  beforeRouteEnter(to, from, next) {
    if (!to.meta?.isCreationStep) {
      next(
        `${ROUT_PATH.CREATING_RESUME_PATH.RESUME_CREATION}/${ROUT_PATH.CREATING_RESUME_PATH.STEP_INIT}`
      );
    }

    if (
      to.name === CREATING_RESUME_NAMES.STEP_FINAL &&
      !CompletedTemplate.get()
    ) {
      next(ROUT_PATH.BROWS_RESUME_TEMPLATES);
      AppToast.toastError("No resume for saving...");
      return;
    }

    if (to.name !== CREATING_RESUME_NAMES.STEP_INIT && !ResumeHash.get()) {
      if (
        to.name === CREATING_RESUME_NAMES.STEP_FINAL &&
        !!CompletedTemplate.get()
      ) {
        next();
        return;
      }

      next(ROUT_PATH.BROWS_RESUME_TEMPLATES);
      AppToast.toastError("No resume for editing...");
      return;
    }

    next();
  },
};
</script>
